<template>
  <div class="home" style="margin-top: 40px">
    <!--    放地图的容器-->
    <div id="main" ref="chart" style="width: 860px; height:460px;margin: 0 auto;"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import china from "echarts/map/js/china";
export default {
  name: "ChinaMap",
  data() {
    return {
      // 地图上显示的疫情数据
      dataList: [
        { name: "南海诸岛", value: 1054 },
        { name: "北京", value: 19 },
        { name: "天津", value: 11 },
        { name: "上海", value: 31 },
        { name: "重庆", value: 28 },
        { name: "河北", value: 13 },
        { name: "河南", value: 23 },
        { name: "云南", value: 14 },
        { name: "辽宁", value: 14 },
        { name: "黑龙江", value: 12 },
        { name: "湖南", value: 15 },
        { name: "安徽", value: 26 },
        { name: "山东", value: 58 },
        { name: "新疆", value: 6 },
        { name: "江苏", value: 33 },
        { name: "浙江", value: 34 },
        { name: "江西", value: 19 },
        { name: "湖北", value: 16 },
        { name: "广西", value: 11 },
        { name: "甘肃", value: 15 },
        { name: "山西", value: 19 },
        { name: "内蒙古", value: 9 },
        { name: "陕西", value: 23 },
        { name: "吉林", value: 10 },
        { name: "福建", value: 41 },
        { name: "贵州", value: 8 },
        { name: "广东", value: 36 },
        { name: "青海", value: 8 },
        { name: "西藏", value: 6 },
        { name: "四川", value: 24 },
        { name: "宁夏", value: 17 },
        { name: "海南", value: 7 },
        { name: "台湾", value: 3 },
        { name: "香港", value: 5 },
        { name: "澳门", value: 5 },
      ],
    };
  },
  methods: {
    initChart() {
      // 获取存放地图的dom元素
      let myChart = echarts.init(this.$refs.chart);
      // 指定图表的配置项和数据
      let option = {
        // 地图标题
        // title: {
        //   text: '中国地图',
        //   x: 'center'
        // },
        // 放上鼠标后显示的新
        tooltip: {
          trigger: "item",
          formatter: function (e, t, n) {
            return (
              e.data.name +
              "<br/>政策资源" +
              '<span style="color:#ff0000;font-size:18px;margin-left:15px">' +
              e.data.value +
              "</span>"
            );
          },
          textStyle: {
            align: "left",
          },
        },
        // 设置地图数据
        series: [
          {
            type: "map",
            map: "china",

            label: {
              show: true,
              color: "#5559f4",
              fontsize: 10,
              formatter: (res) => {
                return res.data.name;
              },
            },
            itemStyle: {
              // borderColor: '#d1dbfd',
              borderWidth: 0.1,
            },
            // 选中时候显示内容
            emphasis: {
              label: {
                color: "black",
                fontsize: 12,
              },
              itemStyle: {
                // borderColor: '#00FFFF',
                areaColor: "#568afa",
              },
            },
            roam: false, //禁止缩放
            data: this.dataList,
          },
        ],
        // 视觉映射组件，根据数据的不同，地图显示不同的颜色
        visualMap: {
          type: "piecewise",
          min: 0,
          max: 1000,
          left: 10,
          bottom: 40,
          showLabel: !0,
          text: ["高", "低"],
          pieces: [
            {
              gt: 1000,
              // label: '> 100 ',
              label: " ",
              color: "#568afa",
              symbol: "roundRect",
            },
            {
              gte: 100,
              lte: 1000,
              label: " ",
              // label: '10 - 100 ',
              color: "#aba8fe",
            },
            {
              gte: 10,
              lt: 100,
              label: " ",
              // label: '1 - 9 ',
              color: "#cdd1fe",
            },
            {
              // value: 0,
              // value: '',
              gte: 1,
              lt: 10,
              label: " ",
              color: "#e6edfe",
              symbol: "roundRect",
            },
          ],
          show: !0,
        },
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);

      myChart.on("click", function (params) {
        var city = params.name;
        // console.log(params.name);
        // var urlArr = ["http://localhost:8080/#/member5/", "#", "#"];

        // if(params.name=="台湾"){
        location.href = "http://www.kehui.cloud/#/member5/?city" + "=" + city;
        // }
        
      });
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>
<style scoped>
</style>