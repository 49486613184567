<template>
    <div class="home">
        <!--导航栏-->
        <con-tab></con-tab>
        <home-hospital class="nei_box"></home-hospital>
    </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import HomeHospital from "./base/HomeHospital";  //首页内部
export default {
    name: "Home",
    data () {
        return {
        }
    },
    created () {

    },
    methods: {
        ThreeClick (id) {
            //console.log(id);
            this.NavId(id)
        },
    },
    components: {
        ConTab,
        HomeHospital,
    }
}
</script>

<style scoped lang="less">
	
/*.swiper_box{*/
/*position: relative;*/

/*}*/
/*.nei_box{*/
/*position: absolute;*/
/*top: 30px;*/
/*z-index: 9999;*/
/*}*/
</style>
