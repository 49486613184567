<template>
	<div>
		<kefuright></kefuright>
		<home-swiper> </home-swiper>
		<!-- 科汇云 -->
		<div class="kebox">
			<div class="wrap">
				<div class="keleft animate__animated animate__fadeInUp">
					<div class="kltop">
						<div class="kltitle">
							科汇云数据中心
						</div>
						<img src="../../../assets/img/images/lansan_06.png" class="klpic" alt="">
					</div>
					<div class="klcen">
						<div class="klli">
							<div class="center">
								<img src="../../../assets/img/images/qa_05.png" class="cenpic" alt="">
								<div class="centitle">
									企业机构
									<span>/1193个</span>
								</div>
								<div class="xiaoabox">
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											中小微企业
											<span>(612)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											高新技术企业
											<span>(236)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											规上企业
											<span>(329)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											上市企业
											<span>(3)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											独角兽企业
											<span>(13)</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="klli">
							<div class="center">
								<img src="../../../assets/img/images/qb_05.png" class="cenpic" alt="">
								<div class="centitle">
									科技成果
									<span>/39140个</span>
								</div>
								<div class="xiaoabox">
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											1-3级成熟度
											<span>(2623)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											4-6级成熟度
											<span>(3265)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											7-9级成熟度
											<span>(9550)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											农林类成果
											<span>(22143)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											装备制造成果
											<span>(1562)</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="klli">
							<div class="center">
								<img src="../../../assets/img/images/qc_05.png" class="cenpic" alt="">
								<div class="centitle">
									高校院所
									<span>/309个</span>
								</div>
								<div class="xiaoabox">
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											985/211工程高校
											<span>(67)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											科研院校
											<span>(27)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											一流学科建设高校
											<span>(10)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											中央部属高校
											<span>(4)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											部省高校
											<span>(78)</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="klli">
							<div class="center">
								<img src="../../../assets/img/images/qd_05.png" class="cenpic" alt="">
								<div class="centitle">
									人才引进
									<span>/19461个</span>
								</div>
								<div class="xiaoabox">
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											院士
											<span>(53)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											领军人才
											<span>(95)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											博士
											<span>(105)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											技术转移经纪人
											<span>(1696)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											海外留学硕士
											<span>(577)</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="klli">
							<div class="center">
								<img src="../../../assets/img/images/qa_05.png" class="cenpic" alt="">
								<div class="centitle">
									园区平台
									<span>/13093个</span>
								</div>
								<div class="xiaoabox">
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											高新区
											<span>(612)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											工业园区
											<span>(8418)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											市级孵化器
											<span>(1206)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											省级孵化器
											<span>(1349)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											国家级孵化器
											<span>(856)</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="klli">
							<div class="center">
								<img src="../../../assets/img/images/qb_05.png" class="cenpic" alt="">
								<div class="centitle">
									创新平台
									<span>/17102个</span>
								</div>
								<div class="xiaoabox">
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											创新中心
											<span>(40)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											创新研发机构
											<span>(66)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											省市级企业技术中心
											<span>(15571)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											省市工程技术中心
											<span>(566)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											院士工作站
											<span>(859)</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="klli">
							<div class="center">
								<img src="../../../assets/img/images/qc_05.png" class="cenpic" alt="">
								<div class="centitle">
									金融机构
									<span>/215个</span>
								</div>
								<div class="xiaoabox">
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											天使投资机构
											<span>(70)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											银行及保险公司
											<span>(38)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											基金/私募平台
											<span>(32)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											天使投资个人
											<span>(35)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											机构富余基金转移投资
											<span>(40)</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="klli">
							<div class="center">
								<img src="../../../assets/img/images/qd_05.png" class="cenpic" alt="">
								<div class="centitle">
									创投项目
									<span>/2318个</span>
								</div>
								<div class="xiaoabox">
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											节能环保
											<span>(55)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											新兴信息产业
											<span>(28)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											生物产业
											<span>(312)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											新能源
											<span>(3)</span>
										</div>
									</div>
									<div class="cenli">
										<div class="landian"></div>
										<div class="xiaotxt">
											高端装备制造业
											<span>(1009)</span>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div class="keright animate__animated animate__fadeInUp">
					<div class="krtop">
						<div class="krtitle">
							科汇云动态
						</div>
						<div class="seemore" @click="more">
							查看更多>
						</div>
					</div>

					<div class="krcen">

						<ul class="item">
							<li class="krcenli" v-for="(item,index) in horseLampList" :key="item.id"
								@click="newXq(item.id)" v-if="index < 7">
								<img src="../../../assets/img/images/xinb_03.png" v-if="index<3" class="krpic" alt="" />
								<img src="../../../assets/img/images/xina_03.png" v-else class="krpic" alt="" />
								<div class="kran">
									<div class="antitle oneline">
										{{item.title}}
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<!-- 政府云 -->
		<div class="zhengbox animate__animated animate__fadeInUp">
			<div class="zhengtop ">
				<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
				<div class="zhengtitle">政府云</div>
				<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
			</div>
			<div class="qifu">
				<div class="qili" :class="{ qiactive: isActive == index }" v-for="(item, index) in yunList" :key="index"
					@click="yuntab(index)">
					{{ item.name }}
				</div>
			</div>
			<div class="banxin" v-if="isActive === 0">
				<div class="img_box_two">
					<router-link :to="{ path: '/ServiceCloud' }" target="_blank">
						<serviceCloud v-if="isChildUpdate1"></serviceCloud>
					</router-link>
				</div>
			</div>
			<div class="banxin" v-if="isActive === 1">
				<div class="img_box_two">
					<!-- <img @click="handlePictureCardPreview($event)" src="../../../assets/img/zhengfuyun/innovation-img-2.jpg" alt="" /> -->
					<router-link :to="{ path: '/EnterpriseCloud' }" target="_blank">
						<enterpriseCloud v-if="isChildUpdate2"></enterpriseCloud>
					</router-link>
				</div>
			</div>
		</div>

		<!-- 企业云 -->
		<div class="qiyebox animate__animated animate__fadeInUp">
			<div class="qiyetop">
				<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
				<div class="zhengtitle">企业云</div>
				<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
			</div>
			<div class="banxin">
				<div class="xiangbox">
					<div class="xileft">
						<div class="xili" @mouseover="qitab(index)" v-for="(item , index) in qiList"
							:class="{ qiActive: qiActive == index }">
							<div class="xitop">
								<img :src="qiActive == index ? item.chose : item.nochose" class="xipic" alt="" />
								<div class="xititile">
									{{item.name}}
								</div>
							</div>
							<div class="xiline"></div>
						</div>
					</div>
					<div class="xiyou">
						<div class="xiyli" @click="goTojszy" :class="{ xiavtive: qiActive == 0 }">
							<img src="../../../assets/img/images/da_03.png" class="ypic" alt="" />
							<div class="ytitle">
								技术转移专项
							</div>
							<div class="ytxt twolines">
								产学研合作、中试、研发线路设计、评估交易及技术合同登记等技术转移全流程服务
							</div>
						</div>
						<div class="xiyli" @click="goTozscp" :class="{ xiavtive: qiActive == 1 }">
							<img src="../../../assets/img/images/db_03.png" class="ypic" alt="" />
							<div class="ytitle">
								知识产权专项
							</div>
							<div class="ytxt twolines">
								提供商标、版权、植物新品种、集成电路
								图指导及检测、试验、测评及情报研究服务
							</div>
						</div>
						<div class="xiyli" @click="goTokjjr" :class="{ xiavtive: qiActive == 2 }">
							<img src="../../../assets/img/images/dc_03.png" class="ypic" alt="" />
							<div class="ytitle">
								科技金融专项
							</div>
							<div class="ytxt twolines">
								知产价值评估与融资、尽调、专利权质押
								验资与专审、研发费加计扣除及上市辅导
							</div>
						</div>
						<div class="xiyli" @click="goTozcyy" :class="{ xiavtive: qiActive == 3 }">
							<img src="../../../assets/img/images/dd_03.png" class="ypic" alt="" />
							<div class="ytitle">
								政策应用专项
							</div>
							<div class="ytxt twolines">
								高企、专精特新与高成长型、双软、行
								业小巨人专家院士工作站及体系认证与贯标
							</div>
						</div>
						<div class="xiyli" @click="goTozqzx" :class="{ xiavtive: qiActive == 4 }">
							<img src="../../../assets/img/images/de_03.png" class="ypic" alt="" />
							<div class="ytitle">
								项目生命周期
							</div>
							<div class="ytxt twolines">
								可研、节能、稳评、水士保持、行洪、专项
								债、收益与融资自求平衡、法律财务意见书
							</div>
						</div>
						<div class="xiyli" @click="goTohumancloud" :class="{ xiavtive: qiActive == 5 }">
							<img src="../../../assets/img/images/da_03.png" class="ypic" alt="" />
							<div class="ytitle">
								技术人才服务
							</div>
							<div class="ytxt twolines">
								提供院士专家、海归、博士、教授、科研
								团队等资源，组织人才交流与技术转移活动
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 科服云 -->
		<div class="zhengbox animate__animated animate__fadeInUp">
			<div class="zhengtop pb60">
				<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
				<div class="zhengtitle">科服云</div>
				<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
			</div>
			<div class="banxin">
				<div class="fubox">
					<div class="fuleft">
						<div class="fltop">
							<div class="fli" :class="{ yunactive: keActive == index }" @mouseover="ketab(index)"
								v-for="(item , index) in keList">
								<div class="fltitle">
									{{item.name}}
								</div>
								<img :src="item.pic" v-if="keActive == 0" class="showpic" alt="">
								<img :src="item.pic" v-else class="showpica" alt="">
							</div>
						</div>
						<div v-if="keActive === 0 ">
							<city style="margin-top: -10px"></city>
						</div>
						<div v-if="keActive === 1">
							<div class="fuli" v-for="(item,index) in LibraryList" :key="item.id"
								@click="nan_jing(item.id)">
								<img src="../../../assets/img/images/xinb_03.png" v-if="index<3" class="fupic" alt="" />
								<img src="../../../assets/img/images/xina_03.png" v-else class="fupic" alt="" />
								<div class="futxt oneline">
									{{item.title}}
								</div>
								<div class="futime">
									{{item.created_at.substring(0, 10)}}
								</div>
							</div>
						</div>
					</div>
					<div class="fuyou">
						<div class="fubg">
							<div class="bgnei">
								<div class="bgtitle">
									政策速递
								</div>
								<div class="bgtxt">
									最及时、最全面的智能政策系统，全国
									科技项目政策一触即达。
								</div>
								<div class="bgmian" @click="goTotest">
									<div class="maintxt">
										免费体验
									</div>
									<img src="../../../assets/img/images/baisan_07.png" class="mainpic" alt="" />
								</div>
							</div>
						</div>
						<div class="jibox">
							<div class="jitop">
								<div class="jili" :class="{jiactive: jiActive == index }" @mousemove="jitab(index)"
									v-for="(item , index) in jiList">
									<img :src="item.pic" class="jipic" alt="" />
									<img :src="item.pica" class="jipica" alt="" />
									<div class="jititle">
										{{item.name}}
									</div>
								</div>
							</div>
							<div class="yifu" v-if="jiActive === 0">
								<p class="ninelines">
									一种具有可折叠门板产品的自卸车厢；一种脱胶废水热能回收装置；酷盟手机应用商店APP软件；一种电容导针电子软件开发；一种鹅肉罐头的配方及其制备工艺；带充电功能的旅行包；一种发酵料用菌类扩培装置；新型变频采煤机电缆；高热电能烘干麻纤维（苎麻）技术；高热电能烘干麻纤维（苎麻）技术；一种钢结构装备用打孔装置；一种防止污染液压油的空心活塞杆组件；一种茶园用卷叶扶正嫩芽采摘机；一种便于安装的环网柜；不锈钢弯曲设备；一种大型电容器批量自动干燥装置；一种采用蒸汽加热的豆筋、豆笋锅；金槐嫁接育苗方法；一种百部种植方法等技术。
								</p>
							</div>
							<div class="yifu" v-if="jiActive === 1">
								<p class="ninelines">
									达州市科技开发中心、达州市农业科学研究院、达州市玄武岩产业纤维研究院、达州市智信技术转移中心、达州市联创技术转移中心等。
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 人才云 -->
		<div class="rencaibox animate__animated animate__fadeInUp">
			<div class="pt60">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">人才云</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
			</div>
			<div class="banxin">
				<div class="renbox">
					<div class="renli" v-for="item in renList" @click="goTorencai(item,index)">
						<img :src="item.pic" class="renpic" alt="" />
						<div class="renxia">
							<div class="rentitle oneline">
								{{item.title}}
							</div>
							<div class="rentxt twolines">
								{{item.txt}}
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<!-- 供需发布大厅 -->
		<div class="gongbg animate__animated animate__fadeInUp">
			<div class="pt60">
				<div class="gongtop">
					<img src="../../../assets/img/images/tbl_08.png" class="zhengpic" alt="" />
					<div class="zhengbai">供需发布大厅</div>
					<img src="../../../assets/img/images/tbr_08.png" class="zhengpic" alt="" />
				</div>
			</div>
			<div class="banxin">
				<div class="gongtxt">
					技术经理人通过企业发布需求，根据服务平台连身定制解决方案，成功合作项目上百余项，直接产生经济价值上百余万元。 供需发布大厅：生产企业发布需求，平台根据实际情况提供契合技术支
					持，实现双向合作，共创双赢。
				</div>
				<div class="xuqiubox">
					<div class="xuqiuli" @click="goToxuda">
						需求大厅
					</div>
					<div class="xuqiuli xuactive" @click="goToxufa">
						需求发布
					</div>
				</div>
			</div>
		</div>

		<!-- 成功案例 -->
		<div class="winall animate__animated animate__fadeInUp">
			<div class="banxin">
				<div class="winbox">
					<div class="wintop">
						<div class="wline"></div>
						<img src="../../../assets/img/images/wina_03.png" class="winpic" alt="" />
						<div class="wintitle">成功案例</div>
						<img src="../../../assets/img/images/winb_03.png" class="winpic" alt="" />
						<div class="wline"></div>
					</div>
					<div class="wincen">
						<el-carousel height="330px" arrow="always" loop="true" :autoplay="false">
							<el-carousel-item class="el-car-item" v-for="(list, index) in dataList" :key="index">
								<div v-for="(item,index2) in list" :key="index2" class="winli" @click="an_li(item)">

									<img :src="urlImg + item.img" class="wintu" alt="" />
									<div class="wintxt oneline">
										{{item.title}}
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>

				<div class="pt60">
					<div class="qiyetop">
						<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
						<div class="zhengtitle">机构云</div>
						<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
					</div>
				</div>
				<div class="goubox">
					<div class="gouli">
						<img src="../../../assets/img/images/ga_03.png" class="goupic" alt="" />
						<div class="goutitle oneline" @click="goTogaoxiao(1)">
							高校
						</div>
						<div class="goutxt twolines" @click="goTogaoxiao(1)">
							平台提供技术转移转化、知识产权、政策
							运用咨询、科技金融、科技项目全过程
						</div>
						<div class="goudi">
							<div class="gouleft" @click="goTogaoxiao(1)">
								进入高校
							</div>
							<div class="gouright" @click="cheng_guo">
								进入成果发布入口
							</div>
						</div>
					</div>

					<div class="gouli">
						<img src="../../../assets/img/images/gb_03.png" class="goupic" alt="" />
						<div class="goutitle oneline" @click="goTogaoxiao(2)">
							科研院所
						</div>
						<div class="goutxt twolines" @click="goTogaoxiao(2)">
							平台机构积极与各个研院所合作，协调省
							农业机械研究设计院、省机械研究设计...
						</div>
						<div class="goudi">
							<div class="gouleft" @click="goTogaoxiao(2)">
								进入科研院所
							</div>
							<div class="gouright" @click="cheng_guo">
								进入成果发布入口
							</div>
						</div>
					</div>
					<div class="gouli">
						<img src="../../../assets/img/images/gc_03.png" class="goupic" alt="" />
						<div class="goutitle oneline" @click="goTogaoxiao(3)">
							其他科研组织
						</div>
						<div class="goutxt twolines" @click="goTogaoxiao(3)">
							辐射和吸引其它科研组织，技术成果饱满丰富。
						</div>
						<div class="goudi">
							<div class="gouleft" @click="goTogaoxiao(3)">
								进入其他科研组织
							</div>
							<div class="gouright" @click="cheng_guo">
								进入成果发布入口
							</div>
						</div>
					</div>
					<div class="gouli">
						<img src="../../../assets/img/images/gd_03.png" class="goupic" alt="" />
						<div class="goutitle oneline" @click="goTogaoxiao(4)">
							创新个人
						</div>
						<div class="goutxt twolines" @click="goTogaoxiao(4)">
							引导创新个人发展，激发培养全面人才。
						</div>
						<div class="goudi">
							<div class="gouleft" @click="goTogaoxiao(4)">
								进入创新个人
							</div>
							<div class="gouright" @click="cheng_guo">
								进入成果发布入口
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 中试验证基地 -->
		<div class="yanbg animate__animated animate__fadeInUp">
			<div class="pt60">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">中试验证基地</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
			</div>
			<div class="banxin">
				<div class="yanul">
					<div class="yanli" v-for="(item,index) in shiList" v-if="index<4" @click="goTodetail(item.id)">
						<img :src="item.image" class="yanpic" alt="" />
						<div class="yancen">
							<div class="yantitle oneline">
								{{item.title}}
							</div>
							<div class="yanhua">
								<img src="../../../assets/img/images/phone_07.png" class="huapic" alt="" />
								<div class="yantxt">
									{{item.tel}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="chamore" @click="goTodevice">
					查看更多 >>
				</div>
			</div>
		</div>

		<!-- 智产云 -->
		<div class="zhibox animate__animated animate__fadeInUp">
			<div class="banxin">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">智产云</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="zhicen">
					<div class="zhili">
						<div class="zhitop" @click="goTowisdom">
							知识产权出售信息
						</div>
						<div class="chanli" v-for="(item, index) in zhi_arr" :key="index" @click="xq(item.id)">
							<div class="cleft">
								<img src="../../../assets/img/images/zicon_07.png" class="cpic" alt="" />
								<img src="../../../assets/img/images/line_11.png" class="cline" alt="" />
							</div>
							<div class="chancen">
								<div class="chantitle oneline">
									{{ item.name }}
								</div>
								<div class="ctime">
									<img src="../../../assets/img/images/time_14.png" class="timepic" alt="" />
									<div class="ctxt">{{item.begin_date | formatDate}}</div>
								</div>
							</div>
							<div class="chanyou">
								<img src="../../../assets/img/images/see_10.png" class="cypic" alt="" />
								<div class="cytxt">
									256
								</div>
							</div>
						</div>
					</div>

					<div class="zhili">
						<div class="zhired" @click="goTowisdomtwo">
							技术出售信息
						</div>
						<div class="chanli" v-for="(item, index) in zhi_chans" :key="index" @click="cxq(item.id)">
							<div class="cleft">
								<img src="../../../assets/img/images/zicon_07.png" class="cpic" alt="" />
								<img src="../../../assets/img/images/line_11.png" class="cline" alt="" />
							</div>
							<div class="chancen">
								<div class="chantitle oneline">
									{{ item.name }}
								</div>
								<div class="ctime">
									<img src="../../../assets/img/images/time_14.png" class="timepic" alt="" />
									<div class="ctxt">{{item.created_at | formatDate}}</div>
								</div>
							</div>
							<div class="chanyou">
								<img src="../../../assets/img/images/see_10.png" class="cypic" alt="" />
								<div class="cytxt">
									256
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 创新工具 -->
		<div class="chuangbox animate__animated animate__fadeInLeft">
			<div class="pt60">
				<div class="gongtop gongpb">
					<img src="../../../assets/img/images/tbl_08.png" class="zhengpic" alt="" />
					<div class="zhengbai">创新工具</div>
					<img src="../../../assets/img/images/tbr_08.png" class="zhengpic" alt="" />
				</div>
			</div>
			<div class="banxin">
				<div class="huiul">
					<div class="huili" @click="chuang_xin(1)">
						<div class="huiabg">
							<img src="../../../assets/img/images/ca_07.png" class="huipic" alt="" />
							<div class="huititle">
								科汇学院
							</div>
							<div class="huiline"></div>
							<div class="huitxt ">
								科汇学院一直以技术转移、知识产权、政策应用、科技金融......
							</div>
						</div>
						<img src="../../../assets/img/images/gor_07.png" class="gourr" alt="" />
					</div>
					<div class="huili" @click="chuang_xin(2)">
						<div class="huibbg">
							<img src="../../../assets/img/images/cb_07.png" class="huipic" alt="" />
							<div class="huititle">
								知识产权价值测试
							</div>
							<div class="huiline"></div>
							<div class="huitxt ">
								通过企业提供的知识产权成果，根据国家知识产权标准，检测企业自主创新能力。平台的自有的检测依据，采取全要素的数据收集，进行企业检测。
							</div>
						</div>
						<img src="../../../assets/img/images/gor_07.png" class="gourr" alt="" />
					</div>
					<div class="huili" @click="chuang_xin(3)">
						<div class="huicbg">
							<img src="../../../assets/img/images/cc_07.png" class="huipic" alt="" />
							<div class="huititle">
								企业创新能力分析
							</div>
							<div class="huiline"></div>
							<div class="huitxt ">
								平台根据国家提出的科学发展纲要，主要从企业的知识产权...
							</div>
						</div>
						<img src="../../../assets/img/images/gor_07.png" class="gourr" alt="" />
					</div>
					<div class="huili" @click="chuang_xin(4)">
						<div class="huidbg">
							<img src="../../../assets/img/images/cd_07.png" class="huipic" alt="" />
							<div class="huititle">
								高新技术企业合规性分析
							</div>
							<div class="huiline"></div>
							<div class="huitxt ">
								通过线上监测和线下收集企业大数据，根据高新技术企业政策...
							</div>
						</div>
						<img src="../../../assets/img/images/gor_07.png" class="gourr" alt="" />
					</div>
					<div class="huili" @click="chuang_xin(5)">
						<div class="huiebg">
							<img src="../../../assets/img/images/ce_07.png" class="huipic" alt="" />
							<div class="huititle">
								政策匹配系统
							</div>
							<div class="huiline"></div>
							<div class="huitxt ">
								平台收纳技术成果转化最新政策，自动为企业实际情况匹配相关政策。
							</div>
						</div>
						<img src="../../../assets/img/images/gor_07.png" class="gourr" alt="" />
					</div>
				</div>

			</div>
		</div>

		<!-- 创新学院 -->
		<div class="yuanbox animate__animated animate__fadeInLeft">
			<div class="qiyetop">
				<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
				<div class="zhengtitle">创新学院</div>
				<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
			</div>
			<div class="banxin">
				<el-carousel height="570px" :interval="5000" arrow="always">
					<el-carousel-item>
						<div class="lunbox">
							<div class="lunli">
								<div class="lunleft" @click="goTogaoxin">
									<div class="luntitle">
										高新技术企业
										<div class="lunline"></div>
									</div>
									<div class="luntxt">
										创新是企业发展的根本动力，高新技术企业认定目的是引导企业调整产业结构，走自主创新、持续创新的发展道路，激发企业自主创新的热情，提高科技创新能力。于社会，高企认证虽然破坏了行业间公平竟争，但针对中国高新技术型实业大幅落后于其他行业的现状，将产生明显改善;并且高新技术企业大多与各高校有合作关系，高企认证对教育有一定神益。
										于企业，享受税收优惠政策，提高企业竞争力，改善企业生存状况。
									</div>
									<img src="../../../assets/img/images/yin_18.png" class="luntan" alt="" />
								</div>

								<img src="../../../assets/img/images/lunpic_34.png" class="lunpic" alt="" />
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="lunbox">
							<div class="lunli">
								<div class="lunleft" @click="goToce">
									<div class="luntitle">
										政府政策大讲堂
										<div class="lunline"></div>
									</div>
									<div class="luntxt">
										政策作为行动的方针和先行者，把科学理论、先进文化、科技知识、文明素养源源不断注入末梢神经细胞，对一个人来讲是强身壮体，对一个民族和国家的大厦来说是强基固本。把科学理论和知识让千千万万群众所掌握，在实践中，和谐稳定、凝聚力量，对各个建设都有重要意义。
									</div>
									<img src="../../../assets/img/images/yin_18.png" class="luntan" alt="" />
								</div>

								<img src="../../../assets/img/images/lunpic_34.png" class="lunpic" alt="" />
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>
<script>
	import kefuright from '../../../components/common/kefu';
	import HomeSwiper from '../../../components/common/Swiper';
	import {
		formatDate
	} from '../../../common/js/times'; //时间
	import {
		urlImg
	} from '@/common/js/url';
	import city from './city';
	import {
		ServerKnowledge
	} from "@/common/js/api";
	import {
		shou,
		lun_shou,
		demand_succes,
		article_list,
		patent_list,
		achievements_listss,
		shuju,
		shiyan_list
	} from '../../common/js/api';
	import {
		Library
	} from '../../../common/js/api';
	import serviceCloud from '../../zhengfuyun/base/serviceCloud';
	import enterpriseCloud from '../../zhengfuyun/base/enterpriseCloud';
	export default {
		name: 'HomeHospital',
		components: {
			HomeSwiper,
			city,
			serviceCloud,
			enterpriseCloud,
			kefuright
		},
		filters: {
			formatDate(time) {
				time = time * 1000;
				let date = new Date(time);
				return formatDate(date, 'yyyy-MM-dd');
			}
		},
		mounted() {

		},
		data() {
			return {
				isChildUpdate2: true,
				isChildUpdate1: true,
				urlImg: '',
				// 动态
				horseLampList: [],
				// 政策公告
				LibraryList: [],

				// 成功案例
				dataList: [],

				// 智产云
				zhi_arr: [],
				zhi_chans: [],
				// 滑动
				swiperOptions: {
					autoplay: true,
					direction: 'vertical',
					slidesPerView: 5,
					loop: true,
					observer: true,
					observeParents: true,
					speed: 4000,
					mousewheel: false,
					noSwiping: true
				},
				// 政府云切换
				yunList: [{
					id: 1,
					name: '服务监测云'
				}, {
					id: 2,
					name: '企业监测云'
				}],
				isActive: 0,
				// 企业云切换
				qiList: [{
						id: 1,
						name: '技术转移专项',
						chose: require("../../../assets/img/images/zaa_11.png"),
						nochose: require('../../../assets/img/images/za_11.png')
					},
					{
						id: 2,
						name: '知识产权专项',
						chose: require('../../../assets/img/images/zba_11.png'),
						nochose: require('../../../assets/img/images/zb_11.png')
					},
					{
						id: 3,
						name: '科技金融专项',
						chose: require('../../../assets/img/images/zca_11.png'),
						nochose: require('../../../assets/img/images/zc_11.png')
					},
					{
						id: 4,
						name: '政策应用专项',
						chose: require('../../../assets/img/images/zda_11.png'),
						nochose: require('../../../assets/img/images/zd_11.png')
					},
					{
						id: 5,
						name: '项目生命周期',
						chose: require('../../../assets/img/images/zea_11.png'),
						nochose: require('../../../assets/img/images/ze_11.png')
					},
					{
						id: 6,
						name: '技术人才服务',
						chose: require('../../../assets/img/images/zfa_11.png'),
						nochose: require('../../../assets/img/images/zf_11.png')
					}
				],
				qiActive: -1,
				// 科服云
				keList: [{
						id: 1,
						name: '政策地图',
						pic: require('../../../assets/img/images/qieyi_03.png')
					},
					{
						id: 2,
						name: '政策公告',
						pic: require('../../../assets/img/images/qieer_03.png')
					}
				],
				keActive: 1,
				// 技术服务切换
				jiList: [{
						id: 1,
						name: '技术信息汇总',
						pic: require('../../../assets/img/images/tuaa_03.png'),
						pica: require('../../../assets/img/images/tua_03.png'),
					},
					{
						id: 2,
						name: '服务机构汇总',
						pic: require('../../../assets/img/images/tuab_03.png'),
						pica: require('../../../assets/img/images/tuaba_03.png'),
					}
				],
				jiActive: 0,
				// 人才云列表
				renList: [{
						pic: require('../../../assets/img/images/slia_04.png'),
						title: '专家库',
						txt: '诚邀各类学科专家，根据相关政策、数据及资料，独立发表学术见解，提供专业指导意见，共同推进科技成果转化。',
						nav_url: '/scientist'
					},
					{
						pic: require('../../../assets/img/images/slib_04.png'),
						title: '人才招聘',
						txt: '招纳社会各行业领先人才，聚焦行业最新前沿',
						nav_url: '/Recruit'
					},
					{
						pic: require('../../../assets/img/images/slic_04.png'),
						title: '技术经理人',
						txt: '连接科学技术和企业的中间人，助推科技成果转化、帮助企业转型升级的得力助手。',
						nav_url: '/apply'
					},
					{
						pic: require('../../../assets/img/images/slid_04.png'),
						title: '技术需求大厅',
						txt: '技术经理人通过企业发布需求，根据服务平台连身定制解决方案，成功合作项目上百余项，直接产生经济价值上百余万元。',
						nav_url: '/hall'
					}
				],
				shiList: [],
				
				ke_shujv: [],
			};
		},
		computed: {
			optionHover() {
				return {
					step: 0.6, //速度，值越大，速度越快
					hoverStop: true, //鼠标悬停效果，false为关闭该效果
					// singleHeight: 26, //单行停顿
					// waitTime: 2500 //单行停顿的时间
					limitMoveNum: 1 // 开始无缝滚动的数据量
				};
			}
		},
		created() {
			this.urlImg = urlImg();
			this.shiyan()
			this.dong_tai();
			this.getList()
			this.cheng_gongs()
			this.zhi_shi()
			this.zhi_chan()
			this.ServerKnowledge()
			this.ke_shu()
		},

		methods: {
			 ke_shu () {
			            shuju({})
			                .then(res => {
			                    this.ke_shujv = res.data;
			                })
			                .catch(() => { });
			        },
			shiyan() {
				shiyan_list({
				})
					.then(res => {
						this.shiList = res.data
					})
					.catch(err => {});
			},
			
			handleClick_two(tab, event) {
			},
			handleClick(tab, event) {
				if (tab.name == 'first') {
					this.isChildUpdate1 = true;
					this.isChildUpdate2 = false;
				} else if (tab.name == 'second') {
					this.isChildUpdate1 = false;
					this.isChildUpdate2 = true;
				}
			},
			// 政府云切换
			yuntab(index) {
				this.isActive = index;
			},
			// 企业云
			qitab(index) {
				this.qiActive = index;
			},
			// 科服云
			ketab(index) {
				this.keActive = index;
			},
			// 技术服务切换
			jitab(index) {
				this.jiActive = index;
			},
			// 跳转成功案例详情
			goToneedcgal() {
				this.$router.push({
					path: '/needcgal'
				});
			},
			an_li(item) {
				this.$router.push({
					path: '/needcgal',
					query: {
						id: item.id
					}
				});
			},
			// 设备
			goTodevice() {
				this.$router.push({
					path: '/device'
				});
			},
			// 大屏1
			goTodapinga() {
				this.$router.push({
					path: '/ServiceCloud'
				});
			},
			// 大屏2
			goTodapingb() {
				this.$router.push({
					path: '/enterpriseCloud'
				});
			},

			// 查看更多动态
			more() {
				this.$router.push({
					path: '/dynamicS'
				});
			},
			// 科服云动态详情
			newXq(id) {
				this.$router.push({
					path: '/Dynamicxq',
					query: {
						id: id
					}
				});
			},
			dong_tai() {
				article_list({})
					.then(res => {
						this.horseLampList = res.data.data;
					})
					.catch(err => {});
			},
			
			// 企业云
			goTojszy() {
				this.$router.push({
					path: '/special?s=jszy'
				})
			},
			goTozscp() {
				this.$router.push({
					path: '/special?s=zscq'
				})
			},
			goTokjjr() {
				this.$router.push({
					path: '/special?s=kjjr'
				})
			},
			goTozcyy() {
				this.$router.push({
					path: '/special?s=zcyy'
				})
			},
			goTozqzx() {
				this.$router.push({
					path: '/special?s=zqzx'
				})
			},
			goTohumancloud() {
				this.$router.push({
					path: '/humancloud'
				})
			},
			// 政策公告
			nan_jing(ids) {
				this.$router.push({
					path: '/member5/test5-2',
					query: {
						id: ids
					}
				});
			},
			getList(list) {
				Library({
						page: 1,
						limits: 7
					})
					.then(res => {
						this.LibraryList = res.data.data;
					})
					.catch(() => {});
			},
			goTotest() {
				this.$router.push({
					path: '/member5/test5-2',
				});
			},
			// 人才云
			goTorencai(item, index) {
				this.$router.push({
					path: item.nav_url
				});
			},
			// 需求大厅
			goToxuda() {
				this.$router.push({
					path: '/hall',
				});
			},
			// 需求发布
			goToxufa() {
				this.$router.push({
					path: '/need',
				});
			},
			goTogaoxin(){
				this.$router.push({
					path: '/kehuixy?id=78',
				});
			},
			goToce(){
				this.$router.push({
					path: '/kehuixy?id=78',
				});
			},
			// 高校
			goTogaoxiao(id) {
				if (id == 1) {
					this.$router.push({
						path: '/gaoxiao?id=108'
					});
				} else if (id == 2) {
					this.$router.push({
						path: '/gaoxiao?id=109'
					});
				} else if (id == 3) {
					this.$router.push({
						path: '/gaoxiao?id=110'
					});
				} else if (id == 4) {
					this.$router.push({
						path: 'gaoxiao?id=111'
					});
				}
			},
			// 成功案例
			cheng_gongs() {
				demand_succes({
						address: '',
						territory: '',
						is_grant: '',
						service: '',
						urgency: '',
						keywords: '',
						created_at: '',
						type: '',
						page: 1,
						limits: 4
					})
					.then(res => {
						this.dataList = res.data.data;
						this.getDataSource()
					})
					.catch(err => {});
			},


			getDataSource() {
				let newDataList = []
				let current = 0
				if (this.dataList && this.dataList.length > 0) {
					for (let i = 0; i <= this.dataList.length - 1; i++) {
						if (i % 4 !== 0 || i === 0) {
							if (!newDataList[current]) {
								newDataList.push([this.dataList[i]])
							} else {
								newDataList[current].push(this.dataList[i])
							}
						} else {
							current++
							newDataList.push([this.dataList[i]])
						}
					}
				}
				this.dataList = [...newDataList]
			},
			// 进入成果发布入口
			cheng_guo() {
				this.$router.push({
					path: "/sucessresult",
				});
			},
			// 智产云
			// 
			goTowisdom() {
				this.$router.push({
					path: '/wisdom#tab2'
				});
			},
			goTowisdomtwo() {
				this.$router.push({
					path: '/wisdom#tab1'
				});
			},
			zhi_chan() {
				achievements_listss({
						page: 1,
						limits: 5
					})
					.then(res => {
						this.zhi_chans = res.data.data;
					})
					.catch(err => {});
			},
			zhi_shi() {
				patent_list({
						page: 1,
						limits: 5
					})
					.then(res => {
						this.zhi_arr = res.data.data;
					})
					.catch(err => {});
			},
			// 进入详情
			xq(id) {
				this.$router.push({
					path: '/ZReleaseXQ',
					query: {
						id: id
					}
				});
			},
			goTodetail(id) {
				this.$router.push({
					path: '/devicedetail',
					query: {
						id: id
					}
				});
			},
			// 成果详情
			cxq(id) {
				this.$router.push({
					path: '/Achievement_details',
					query: {
						id: id
					}
				});
			},
			// 创新详情
			chuang_xin(id) {
				if (id == 1) {
					this.$router.push({
						path: '/kehuixy'
					});
				} else if (id == 2) {
					this.$router.push({
						path: '/ceshijz'
					});
				} else if (id == 3) {
					this.$router.push({
						path: '/qiyecx'
					});
				} else if (id == 4) {
					this.$router.push({
						path: '/qiyecx'
					});
				} else if (id == 5) {
					this.$router.push({
						path: '/zhengcepp'
					});
				}
			},
		}
	};
</script>
<style scoped lang="less">
	.el-car-item {
		width: 100%;
		display: flex;
	}

	.el-carousel--horizontal {
		width: 100%;
	}

	.el-car-item {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		border-left: 1px solid #ffffff;
	}

	/deep/.el-carousel__button {
		background-color: transparent;
		height: 5px !important;
		width: 20px !important;
		border-radius: 25px;
		background-color: #0289f4;
		opacity: 0.3;
	}

	/deep/.el-carousel__indicator.is-active button {
		background-color: #0289f4;
		height: 5px !important;
		width: 20px !important;
		border-radius: 25px;
	}

	/deep/.el-carousel__indicators--horizontal {
		bottom: 5px;
	}

	/deep/.el-carousel__arrow {
		background-color: transparent !important;
		right: 1px;
	}

	/deep/.el-carousel__arrow i {
		font-size: 40px;
		color: #aaaaaa;
	}

	/deep/.el-carousel__arrow--left {
		left: 0;
	}

	/deep/.el-carousel__arrow i:hover {
		color: #0289f4 !important;
	}

	.governmentss {
		width: 100%;
		margin-bottom: 60px !important;
	}

	.img_box_two {
		width: 100%;
		height: 500px;
		border-radius: 5px;
		overflow: hidden;
		background-color: #eee;
		cursor: pointer;
	}

	.kebox {
		background-color: #f6f6f6;
		padding-bottom: 60px;

		.wrap {
			width: 1400px;
			margin: 0 auto;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.keleft:hover .klcen {
				overflow: auto;
				height: auto !important;
			}

			.keleft {
				margin-top: -65px;
				width: 970px;
				border-radius: 5px;
				background: rgba(255, 255, 255, 0.8);
				height: 495px;
				z-index: 66;

				.kltop {
					display: flex;
					align-items: center;
					justify-content: space-between;

					height: 65px;
					padding: 0 20px;

					.kltitle {
						font-size: 22px;
						color: #222222;
						font-weight: bold;
					}

					.klpic {
						width: 14px;
						height: 8px;
						cursor: pointer;
					}
				}

				.klcen {
					background: #fff;
					display: flex;
					padding: 0 20px;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					height: 430px;
					overflow: hidden;

					.klli {
						margin-bottom: 20px;
						width: 220px;
						height: 410px;
						background-image: linear-gradient(#ffffff, #ffffff, #f8fbff, #ecf3fe);
						border-radius: 7px;
						box-shadow: 0 4px 8px #ecf3fe;

						.center {
							text-align: center;
							padding: 22px 0 4px;

							.cenpic {
								width: 107px;
								height: 107px;
								border-radius: 50%;
								box-shadow: 0 0 20px #e0e8ff;
							}

							.centitle {
								font-size: 20px;
								color: #222222;
								font-weight: bold;
								padding-top: 13px;
								padding-bottom: 5px;

								span {
									color: #f26202;
									font-size: 20px;
									font-weight: bold;
								}
							}

							.xiaoabox {
								padding: 0 15px;

								.cenli:last-child {
									border: none;
								}

								.cenli {
									cursor: pointer;
									display: flex;
									align-items: center;
									padding: 21px 0 12px;
									border-bottom: 1px dashed #d6d7d7;

									.landian {
										width: 3px;
										height: 3px;
										background-color: #0289f4;
										flex-shrink: 0;
										margin-right: 10px;
									}

									.xiaotxt {
										font-size: 13px;
										color: #666666;

										span {
											color: #0289f4;
										}
									}
								}
							}

						}
					}
				}
			}

			.keright {
				margin-top: -65px;
				width: 418px;
				border-radius: 5px;
				background: rgba(255, 255, 255, 0.8);
				height: 495px;

				.krtop {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 65px;
					padding: 0 20px;

					.krtitle {
						font-size: 22px;
						color: #222222;
						font-weight: bold;
					}

					.seemore {
						font-size: 16px;
						color: #666666;
						cursor: pointer;
					}

					.seemore:hover {
						color: #0289f4;
					}
				}

				.swiper-container {
					height: 405px !important;
					overflow: hidden;
				}

				.krcen {
					padding: 10px 20px 0;

					.krcenli {
						height: 60px;
						display: flex;
						align-items: center;
						cursor: pointer;

						.krpic {
							width: 39px;
							height: 35px;
							flex-shrink: 0;
						}

						.kran {
							padding: 7px 0 0 10px;
							flex-grow: 1;
							max-width: 85%;
							.antitle {
								
								font-size: 18px;
								color: #000000;
							}

							.antitle:hover {
								color: #0289f4;
							}
						}
					}
				}
			}
		}
	}

	.oneline {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
	}

	.twolines {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

	.threelines {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	.zhengbox {
		background-color: #ffffff;
		padding: 60px 0;

		.pb60 {
			padding-bottom: 60px !important;
		}

		.zhengtop {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 48px;

			.zhengpic {
				width: 118px;
				height: 27px;
				flex-shrink: 0;
			}

			.zhengtitle {
				font-size: 36px;
				color: #222222;
				font-weight: bold;
				padding: 0 35px;
			}
		}

		.qifu {
			border-bottom: 1px solid #e5e5e5;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 50px;

			.qili {
				font-size: 24px;
				color: #333333;
				margin: 0 50px;
				border-bottom: 3px solid transparent;
				padding-bottom: 14px;
				cursor: pointer;
			}

			.qiactive {
				border-bottom: 3px solid #0289f4;
				color: #0289f4;
			}
		}

		.daping {
			width: 100%;
			height: 690px;
			overflow: hidden;
		}
	}

	.qiyetop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 60px;

		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}

		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}

		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}

	.qiyebox {
		background-color: #f6f6f6;
		padding: 60px 0;

		.xiangbox {
			display: flex;
			align-items: flex-start;
			background-color: #ffffff;

			.xileft {
				width: 280px;
				flex-shrink: 0;

				.xili:last-child {
					.xiline {

						height: 0px !important;
						background-color: #d5d6d9;
						margin: 0 auto;
					}
				}

				.xili {
					transition: 0.5s ease-in-out;
					height: 85px;
					text-align: center;
					cursor: pointer;

					.xitop {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 84px;

						.xipic {
							width: 27px;
							height: 25px;
						}

						.xititile {
							font-size: 18px;
							color: #888888;
							padding-left: 12px;
						}
					}

					.xiline {
						width: 222px;
						height: 1px;
						background-color: #d5d6d9;
						margin: 0 auto;
					}
				}

				.qiActive {
					background-color: #0289f4;
					box-shadow: 0 0 8px #77c0f9;

					.xitop {

						.xititile {
							font-size: 18px;
							color: #ffffff !important;
							padding-left: 12px;
						}
					}

					.xiline {
						height: 0 !important;
					}
				}
			}

			.xiyou {
				flex: 1;
				border: 1px solid #e6eef5;
				border-bottom: none;
				height: 510px;
				display: flex;
				flex-wrap: wrap;

				.xiyli {
					transition: all 0.5s;
					width: 33.33%;
					border-bottom: 1px solid #e6eef5;
					border-right: 1px solid #e6eef5;
					text-align: center;
					height: 254.5px;
					cursor: pointer;

					.ypic {
						width: 92px;
						height: 88px;
						margin: 30px auto 25px;
					}

					.ytitle {
						font-size: 24rpx;
						color: #222222;
						font-weight: bold;
						padding-bottom: 20px;
					}

					.ytxt {
						padding: 0 30px;
						font-size: 15px;
						color: #222222;
						line-height: 25px;
						text-align: left;
					}
				}

				.xiavtive {
					transition: all 0.5s;
					background-color: #0289f4;

					.ytitle {
						color: #ffffff;
					}

					.ytxt {
						color: #ffffff;
					}
				}

				.xiyli:nth-child(3n) {
					border-right: none;
				}
			}
		}
	}

	.fubox {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.fuleft {
			width: 900px;
			height: 535px;
			box-shadow: 0 2px 8px #dddddd;

			.fltop {
				height: 60px;
				background-color: #e1f1ff;
				display: flex;
				align-items: center;

				.fli {
					height: 60px;
					width: 50%;
					cursor: pointer;
					position: relative;
					text-align: center;

					.fltitle {
						position: relative;
						font-size: 24px;
						color: #555555;
						line-height: 60px;
						z-index: 22;
						font-weight: bold;
					}

					.showpic {
						z-index: 1;
						width: 474px;
						height: 70px;
						position: absolute;
						left: 0;
						bottom: 0;
						display: none;
					}

					.showpica {
						z-index: 1;
						width: 474px;
						height: 70px;
						position: absolute;
						right: 0;
						bottom: 0;
						display: none;
					}
				}

				.yunactive {
					.fltitle {
						color: #0289f4;
					}

					.showpic {
						display: block;
					}

					.showpica {
						display: block;
					}
				}
			}

			.fuli:last-child {
				border: none;
			}

			.fuli {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px dashed #e6e6e6;
				padding: 20px 30px 12px 28px;

				.fupic {
					width: 39px;
					height: 35px;
					flex-shrink: 0;
				}

				.futxt {
					font-size: 18px;
					color: #000000;
					padding: 0 10px;
					flex-grow: 1;
					transition: all 0.5s;
				}

				.futime {
					font-size: 16px;
					color: #888888;
					flex-shrink: 0;
				}
			}

			.fuli:hover {
				.futxt {
					color: #0289f4;
				}
			}
		}

		.fuyou {
			width: 485px;

			.fubg {
				width: 485px;
				height: 170px;
				background: url('../../../assets/img/images/cebg_03.png');
				background-size: cover;
				margin-bottom: 13px;

				.bgnei {
					padding: 25px 0 25px 35px;
					width: 290px;

					.bgtitle {
						font-size: 18px;
						color: #0289f4;
						padding-bottom: 15px;
						font-weight: bold;
					}

					.bgtxt {
						font-size: 15px;
						color: #6e82b8;
						line-height: 22px;
						padding-bottom: 18px;
					}

					.bgmian {
						width: 95px;
						height: 28px;
						border-radius: 30px;
						background-color: #0289f4;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;

						.maintxt {
							font-size: 14px;
							color: #ffffff;
							padding-right: 10px;
						}

						.mainpic {
							width: 7px;
							height: 11px;
						}
					}
				}
			}

			.jibox {
				width: 100%;
				height: 352px;
				background-color: #f7f8fc;

				.jitop {
					display: flex;
					align-items: center;

					.jili {
						width: 50%;
						height: 60px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-bottom: 2px solid #eeeeee;
						cursor: pointer;

						.jipic {
							width: 28px;
							height: 26px;
						}

						.jipica {
							width: 28px;
							height: 26px;
							display: none;
						}

						.jititle {
							font-size: 18px;
							color: #8c8d9e;
							padding-left: 12px;
						}
					}

					.jiactive {
						border-bottom: 2px solid #0289f4;

						.jipic {
							display: none;
						}

						.jipica {
							display: block;
						}

						.jititle {
							font-weight: bold;
							color: #222222;
							padding-left: 12px;
						}
					}
				}

				.yifu {
					padding: 15px 30px 0 20px;

					p {
						font-size: 15px;
						color: #8c8d9e;
						text-indent: 2em;
						line-height: 28px;
						text-align: justify;
					}
				}
			}
		}
	}

	.pt60 {
		padding-top: 60px;
	}

	.rencaibox {
		width: 100%;
		height: 635px;
		background: url('../../../assets/img/images/shoubg_02.png') no-repeat center;
		background-size: 100% 635px;



		.renbox {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.renli {
				width: 330px;
				height: 375px;
				background-color: #ffffff;
				box-shadow: 0 0 5px #dddddd;
				cursor: pointer;
				transition: all 0.5s;
				overflow: hidden;

				.renpic {
					width: 100%;
					height: 200px;
					transition: all 0.5s;
				}

				.renxia {
					padding: 30px 15px 0;

					.rentitle {
						font-size: 24px;
						color: #222222;
						font-weight: bold;
						padding-bottom: 12px;
					}

					.rentxt {
						font-size: 15px;
						color: #222222;
						height: 100px;
						line-height: 28px;
					}

					.chabox {
						display: flex;
						align-items: center;
						cursor: pointer;

						.chatxt {
							font-size: 15px;
							color: #888888;
							padding-right: 10px;
						}

						.chapic {
							width: 10px;
							height: 11px;
						}

						.chapicact {
							width: 10px;
							height: 11px;
							display: none;
						}
					}


				}
			}

			.renli:hover {
				transform: translateY(-5%);
				background-color: #0289f4;

				.renpic {
					transform: scale(1.1);
				}

				.renxia {
					.rentitle {
						color: #ffffff;
					}

					.rentxt {
						color: #ffffff;
					}

					.chabox {
						.chatxt {
							color: #ffffff;
						}

						.chapic {
							display: none;
						}

						.chapicact {
							display: block;
						}
					}
				}
			}
		}
	}

	.gongpb {
		padding-bottom: 60px !important;
	}

	.gongtop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 35px;

		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}

		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}

		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}

	.gongbg {
		width: 100%;
		height: 495px;
		background: url('../../../assets/img/images/gongbg_07.png') no-repeat center;
		background-size: 100% 495px;



		.gongtxt {
			font-size: 16px;
			color: #ffffff;
			text-indent: 2em;
			line-height: 24px;
			padding-bottom: 23px;
		}

		.xuqiubox {
			display: flex;
			align-items: center;
			justify-content: center;

			.xuqiuli {
				width: 200px;
				height: 40px;
				border: 1px solid #ffffff;
				border-radius: 5px;
				margin: 0 24px;
				color: #ffffff;
				text-align: center;
				line-height: 40px;
				font-size: 16px;
				cursor: pointer;
			}

			.xuactive {
				background-color: #0289f4;
				border: 1px solid #0289f4;
			}
		}
	}

	.winall {
		background-color: #f6f6f6;
		display: flex;
		padding-bottom: 60px;
	}

	.winbox {
		height: 420px;
		margin-top: -215px;
		border-radius: 5px;
		background-color: #ffffff;
		box-shadow: 0 3px 6px #dddddd;

		.wintop {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 90px;

			.wline {
				width: 545px;
				height: 1px;
				background-color: #dddddd;
			}

			.winpic {
				width: 22px;
				height: 23px;
				margin: 0 12px;
			}

			.wintitle {
				font-size: 30px;
				color: #333333;
				font-weight: bold;
				padding: 0 20px;
			}
		}

		.wincen {
			padding: 0 17px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			// /deep/.el-carousel__indicators {
			// 	display: none !important;
			// }

			/deep/.el-carousel__arrow {
				background-color: transparent !important;
				right: 1px;
				border: 1px solid #ffffff;
				display: none !important;
			}

			.winli {
				margin-right: 12px;
				width: 330px;
				height: 300px;
				position: relative;
				flex-shrink: 0;
				cursor: pointer;
				transition: all 0.5s;

				.wintu {
					width: 330px;
					height: 260px;
				}

				.wintxt {
					font-size: 16px;
					color: #222222;
					padding: 10px;
					text-align: center;
				}
			}

			.winli:hover {
				transform: translateY(-3%);
				background-color: #0289f4;

				.wintxt {
					color: #ffffff;
					font-weight: bold;
				}
			}
		}

		.winmore {
			text-align: center;
			padding-top: 27px;
			font-size: 16px;
			color: #888888;
			cursor: pointer;
		}

		.winmore:hover {
			color: #0289f4;
		}

	}

	.goubox {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.gouli {
			width: 330px;
			height: 420px;
			background-color: #fff;
			box-shadow: 0 2px 6px #dddddd;
			border-radius: 3px;
			text-align: center;
			position: relative;
			cursor: pointer;
			transition: all 0.5s;

			.goupic {
				width: 130px;
				height: 130px;
				margin: 30px auto 27px;
				transition: all 0.5s;
			}

			.goutitle {
				font-size: 28px;
				color: #222222;
				font-weight: bold;
				padding-bottom: 15px;
			}

			.goutxt {
				font-size: 16px;
				color: #222222;
				line-height: 25px;
				padding: 0 20px;
				text-align: left;
			}

			.goudi {
				width: 100%;
				height: 60px;
				display: flex;
				align-items: center;
				position: absolute;
				left: 0;
				bottom: 0;

				.gouleft {
					width: 50%;
					height: 60px;
					background-color: #ebf2fc;
					line-height: 60px;
					color: #0289f4;
					text-align: center;
					cursor: pointer;
					font-size: 16px;
				}

				.gouright {
					width: 50%;
					height: 60px;
					background-color: #0289f4;
					line-height: 60px;
					color: #ffffff;
					text-align: center;
					cursor: pointer;
					font-size: 16px;
				}
			}
		}

		.gouli:hover {
			transform: translateY(-10%);

			.goupic {
				transform: scale(1.2);
			}

			.goutitle {
				color: #0289f4;
			}
		}
	}

	.yanbg {
		width: 100%;
		height: 595px;
		background: url('../../../assets/img/images/yanbg_02.png') no-repeat center;
		background-size: 100% 595px;

		.yanul {
			display: flex;
			align-items: center;
			.yanli:last-child{
				margin: 0 !important;
			}
			.yanli {
				width: 330px;
				height: 370px;
				background-color: #ffffff;
				cursor: pointer;
				transition: all 0.5s;
				overflow: hidden;
				margin-right: 26.66px;
				.yanpic {
					width: 330px;
					height: 280px;
					transition: all 0.5s;
				}

				.yancen {
					padding: 18px 12px 0;

					.yantitle {
						font-size: 18px;
						color: #222222;
						padding-bottom: 18px;
					}

					.yanhua {
						display: flex;
						align-items: center;

						.huapic {
							width: 17px;
							height: 17px;
							flex-shrink: 0;
						}

						.yantxt {
							font-size: 16px;
							color: #bcbcbc;
							padding-left: 12px;
						}
					}
				}
			}

			.yanli:hover {
				transform: translateY(-5%);

				.yanpic {
					transform: scale(1.05);
				}

				.yancen {
					.yantitle {
						color: #0289f4;
					}
				}
			}
		}

		.chamore {
			text-align: center;
			padding-top: 27px;
			font-size: 16px;
			color: #888888;
			cursor: pointer;
		}

		.chamore:hover {
			color: #0289f4;
		}
	}

	.zhibox {
		background-color: #ffffff;
		padding: 60px 0 45px;

		.zhicen {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.zhili {
				width: 640px;

				.zhired {
					width: 127px;
					height: 40px;
					border-radius: 5px;
					background-image: linear-gradient(#ff8586, #ff2e3d);
					text-align: center;
					line-height: 40px;
					color: #ffffff;
					font-size: 18px;
					margin-bottom: 34px;
				}

				.zhitop {
					width: 162px;
					height: 40px;
					border-radius: 5px;
					background-image: linear-gradient(#5bb6ff, #0389f5);
					text-align: center;
					line-height: 40px;
					color: #ffffff;
					font-size: 18px;
					margin-bottom: 34px;
				}

				.chanli {
					position: relative;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					padding-bottom: 15px;
					padding-left: 5px;

					.cleft {
						width: 15px;
						text-align: center;
						flex-shrink: 0;

						.cpic {
							width: 15px;
							height: 15px;
							margin-bottom: 6px;
						}

						.cline {
							width: 1px;
							height: 35px;
							margin: 0 auto;
						}
					}

					.chancen {
						flex-grow: 1;
						padding: 0 15px;

						.chantitle {
							font-size: 18px;
							color: #000000;
							width: 90%;
							padding-bottom: 14px;
							cursor: pointer;
						}

						.chantitle:hover {
							color: #0289f4;
						}

						.ctime {
							display: flex;
							align-items: center;

							.timepic {
								width: 14px;
								height: 14px;
							}

							.ctxt {
								font-size: 14px;
								color: #888888;
								padding-left: 10px;
							}
						}
					}

					.chanyou {
						display: flex;
						align-items: center;
						flex-shrink: 0;

						.cypic {
							width: 12px;
							height: 10px;
						}

						.cytxt {
							font-size: 12px;
							color: #005eff;
							padding-left: 6px;
						}
					}
				}

				.chanli:last-child {
					.cline {
						display: none;
					}
				}
			}
		}
	}

	.chuangbox {
		width: 100%;
		height: 630px;
		background: url('../../../assets/img/images/chuangbg_08.png') no-repeat center;
		background-size: 100% 630px;

		.huiul {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 405px;

			.huili {
				width: 20%;
				transition: all 1s;
				position: relative;
				cursor: pointer;

				.huiabg {
					transition: all 1s;
					width: 100%;
					height: 405px;
					background: url('../../../assets/img/images/cabg_09.png') no-repeat center;
					background-size: cover;
					text-align: center;
					position: relative;
				}

				.huibbg {
					transition: all 1s;
					width: 100%;
					height: 405px;
					background: url('../../../assets/img/images/cbbg_09.png') no-repeat center;
					background-size: cover;
					text-align: center;
					position: relative;
				}

				.huicbg {
					transition: all 1s;
					width: 100%;
					height: 405px;
					background: url('../../../assets/img/images/ccbg_09.png') no-repeat center;
					background-size: cover;
					text-align: center;
					position: relative;
				}

				.huidbg {
					transition: all 1s;
					width: 100%;
					height: 405px;
					background: url('../../../assets/img/images/cdbg_09.png') no-repeat center;
					background-size: cover;
					text-align: center;
					position: relative;
				}

				.huiebg {
					transition: all 1s;
					width: 100%;
					height: 405px;
					background: url('../../../assets/img/images/cebg_09.png') no-repeat center;
					background-size: cover;
					text-align: center;
					position: relative;
				}

				.huipic {
					width: 46px;
					height: 39px;
					margin: 67px auto 25px;
				}

				.huititle {
					font-size: 20px;
					color: #ffffff;
					font-weight: bold;
					padding-bottom: 45px;
				}

				.huiline {
					width: 30px;
					height: 3px;
					background-color: #ffffff;
					margin: 0 auto 65px;
				}

				.huitxt {
					font-size: 14px;
					color: #e1e1e1;
					padding: 0 20px;
					text-align: left;
					line-height: 25px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				.gourr {
					width: 26px;
					height: 8px;
					position: absolute;
					right: 20px;
					bottom: 20px;
					opacity: 0;
					transition: all 0.5s;
					cursor: pointer;
				}
			}

			.huili:hover {
				background-color: #0164d3;
				height: 445px;

				.huitxt {
					-webkit-line-clamp: 5;
				}

				.huiabg {
					background: transparent;
				}

				.huibbg {
					background: transparent;
				}

				.huicbg {
					background: transparent;
				}

				.huidbg {
					background: transparent;
				}

				.huiebg {
					background: transparent;
				}

				.gourr {
					opacity: 1;
				}
			}
		}
	}

	.yuanbox {
		padding: 60px 0;
		background-color: #ffffff;

		/deep/.el-carousel__button {
			background-color: transparent;
			height: 10px !important;
			width: 10px !important;
			border-radius: 50%;
			border: 3px solid #0289f4;
		}

		/deep/.el-carousel__indicator.is-active button {
			background-color: #0289f4;
			height: 10px !important;
			width: 35px !important;
			border-radius: 25px;
		}

		/deep/.el-carousel__indicators--horizontal {
			bottom: 56px;
		}

		.lunbox {
			text-align: center;
			height: 570px;
			padding: 0 65px;

			.lunli {
				background-image: linear-gradient(#ebf6ff, #ffffff);
				height: 560px;
				box-shadow: 0 0 8px #e1f1ff;
				padding: 60px 55px 0 50px;
				display: flex;
				justify-content: space-between;

				.lunleft {
					text-align: left;
					width: 545px;
					flex-shrink: 0;
					padding-top: 20px;

					.luntitle {
						font-size: 30px;
						color: #222222;
						font-weight: bold;
						padding-bottom: 33px;
						position: relative;
						z-index: 2;

						.lunline {
							width: 55px;
							height: 9px;
							background-color: #0384eb;
							position: absolute;
							left: 0;
							top: 20px;
							z-index: -1;
						}
					}

					.luntxt {
						font-size: 16px;
						color: #888888;
						line-height: 35px;
						padding-bottom: 40px;
					}

					.luntan {
						width: 54px;
						height: 47px;
					}
				}

				.lunpic {
					width: 585px;
					height: 390px;
					border-radius: 8px;
				}
			}
		}
	}

	.ninelines {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 9;
		overflow: hidden;
	}

	.banxin {
		width: 1400px;
		margin: 0 auto;
	}

	@media screen and (max-width: 1200px) {
		.kebox .wrap {
			width: 98%;
			overflow-x: hidden;
		}

		.banxin {
			width: 98%;
		}

		.nei_box {
			overflow: hidden;
		}

		.zhengbox .daping {
			height: auto;
		}

		.kebox .wrap {
			flex-wrap: wrap;
		}

		.kebox .wrap .keleft .klcen {
			height: auto;
		}

		.kebox .wrap .keleft {
			width: 100%;
			margin-top: 0;
			height: auto;
		}

		.kebox .wrap .keleft .kltop {
			padding: 1rem;
			height: auto;
		}

		.kebox .wrap .keleft .klcen .klli {
			width: 49%;
			height: 36rem;
		}

		.kebox .wrap .keleft .kltop .kltitle {
			font-size: 2rem;
		}

		.kebox .wrap .keleft .klcen .klli .center .cenpic {
			width: 8rem;
			height: 8rem;
		}

		.kebox .wrap .keleft .klcen .klli .center .centitle {
			font-size: 1.8rem;
		}

		.kebox .wrap .keleft .klcen .klli .center .centitle span {
			font-size: 1.8rem;
		}

		.kebox .wrap .keleft .klcen .klli .center .xiaoabox .cenli {
			padding: 2rem 0 1.2rem;
		}

		.kebox .wrap .keleft .klcen .klli .center .xiaoabox .cenli .landian {
			width: 0.5rem;
			height: 0.5rem;
		}

		.kebox .wrap .keleft .klcen .klli .center .xiaoabox .cenli .xiaotxt {
			font-size: 1.4rem;
			padding-left: 1rem;
		}

		.kebox .wrap .keright {
			width: 100%;
			margin-top: 2rem;
			height: auto;
		}

		.kebox .wrap .keright .krtop {
			height: auto;
			padding: 1rem;
		}

		.kebox .wrap .keright .krtop .krtitle {
			font-size: 2rem;
		}

		.kebox .wrap .keright .krcen .krcenli .krpic {
			width: 3.9rem;
			height: 3.5rem;
		}

		.kebox .wrap .keright .krcen .krcenli .kran .antitle {
			font-size: 1.8rem;
		}

		.kebox .wrap .keright .krcen .krcenli {
			height: auto;
		}

		.kebox .wrap .keright .krtop .seemore {
			font-size: 1.6rem;
		}

		.zhengbox .zhengtop .zhengtitle {
			font-size: 3rem;
		}

		.zhengbox .qifu .qili {
			font-size: 2.2rem;
		}

		.qiyetop .zhengtitle {
			font-size: 3rem;
		}

		.qiyebox .xiangbox {
			flex-wrap: wrap;
		}

		.qiyebox .xiangbox .xileft {
			width: 100%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			display: none;
		}

		.qiyebox .xiangbox .xileft .xili {
			width: 33.333%;
		}

		.qiyebox .xiangbox .xileft .qiActive .xitop .xititile {
			font-size: 1.6rem;
		}

		.qiyebox .xiangbox .xileft .xili .xitop .xititile {
			font-size: 1.6rem;
		}

		.qiyebox .xiangbox .xileft .xili .xitop .xipic {
			width: 1.7rem;
			height: auto;
		}

		.qiyebox .xiangbox .xiyou {
			height: auto;
		}

		.qiyebox .xiangbox .xiyou .xiyli {
			width: 50%;
			height: 20rem;
		}

		.qiyebox .xiangbox .xiyou .xiyli .ypic {
			width: 7rem;
			height: 6.8rem;
		}

		.qiyebox .xiangbox .xiyou .xiyli .ytitle {
			font-size: 1.6rem;
		}

		.qiyebox .xiangbox .xiyou .xiyli .ytxt {
			font-size: 1.4rem;
			line-height: 1.8rem;
		}

		.fubox {
			flex-wrap: wrap;
		}

		.fubox .fuleft {
			width: 100%;
			height: auto;
		}

		.fubox .fuleft .fltop .fli .fltitle {
			font-size: 1.8rem;
		}

		.fubox .fuleft .fuli .fupic {
			width: 3.4rem;
			height: 3.35rem;
		}

		.fubox .fuleft .fuli .futxt {
			font-size: 1.8rem;
		}

		.fubox .fuleft .fuli .futime {
			font-size: 1.5rem;
		}

		.fubox .fuyou {
			width: 100%;
		}

		.fubox .fuyou .fubg {
			width: 100%;
			height: 15rem;
		}

		.fubox .fuyou .fubg .bgnei {
			width: 55%;
		}

		.fubox .fuyou .fubg .bgnei .bgtitle {
			font-size: 2.2rem;
			padding-top: 1rem;
		}

		.fubox .fuyou .fubg .bgnei .bgtxt {
			font-size: 1.6rem;
			line-height: 2rem;
		}

		.fubox .fuyou .fubg .bgnei .bgmian {
			width: 10rem;
			height: 3.5rem;
		}

		.fubox .fuyou .fubg .bgnei .bgmian .maintxt {
			font-size: 1.6rem;
		}

		.fubox .fuyou .fubg .bgnei .bgmian .mainpic {
			width: 0.7rem;
			height: 1.3rem;
		}
		.fubox .fuyou .jibox{
			display: none;
		}
		.fubox .fuyou .jibox .jitop .jili {
			height: 5rem;
		}

		.fubox .fuyou .jibox .jitop .jili .jipica {
			width: 2.5rem;
			height: 2.3rem;
		}

		.fubox .fuyou .jibox .jitop .jili .jititle {
			font-size: 1.8rem;
		}

		.fubox .fuyou .jibox .yifu p {
			font-size: 1.5rem;
			line-height: 2.4rem;
		}

		.fubox .fuyou .jibox {
			height: auto;
		}

		.rencaibox .renbox {
			flex-wrap: wrap;
		}

		.rencaibox .renbox .renli {
			width: 49%;
			margin-bottom: 1rem;
			height: 25rem;
		}

		.rencaibox {
			height: auto;
			background-size: 100% auto;
			padding-bottom: 2.5rem;
		}

		.rencaibox .renbox .renli .renpic {
			height: 13rem;
		}

		.rencaibox .renbox .renli .renxia .rentitle {
			font-size: 1.8rem;
		}

		.rencaibox .renbox .renli .renxia .rentxt {
			font-size: 1.4rem;
			line-height: 1.7rem;
			height: auto;
		}

		.rencaibox .renbox .renli .renxia .chabox .chatxt {
			font-size: 1.4rem;
			padding-top: 1rem;
		}

		.rencaibox .renbox .renli .renxia .chabox .chapic {
			width: 0.8rem;
			height: 0.8rem;
			margin-top: 1rem;
		}

		.gongtop .zhengbai {
			font-size: 3rem;
		}

		.gongbg {
			height: 35rem;
			background-size: 100% 35rem;
		}

		.gongbg .gongtxt {
			font-size: 1.4rem;
			line-height: 2.2rem;
		}

		.gongbg .xuqiubox {
			padding-top: 2rem;
		}

		.gongbg .xuqiubox .xuqiuli {
			width: 14rem;
			height: 3.5rem;
			font-size: 1.6rem;
			line-height: 3.5rem;
		}

		.winbox .wintop .wintitle {
			font-size: 3rem;
		}

		.winbox .wintop .wline {
			width: 8rem;
		}

		.winbox .wintop {
			height: 7rem;
		}

		.winbox {
			height: auto;
			padding-bottom: 2rem;
		}

		.winbox .wincen {
			flex-wrap: wrap;
		}

		.winbox .wincen .winli {
			width: 49%;
			margin-bottom: 1rem;
			height: 20rem;
		}

		.winbox .wincen .winli .wintu {
			width: 420px;
		}

		.winbox .wincen .winli .wintxt {
			font-size: 1.4rem;
		}

		.winbox .winmore {
			font-size: 1.6rem;
		}

		.goubox {
			flex-wrap: wrap;
		}

		.goubox .gouli {
			width: 49%;
			margin-bottom: 1rem;
			height: 25rem;
		}

		.goubox .gouli .goupic {
			width: 8rem;
			height: 8rem;
		}

		.goubox .gouli .goutitle {
			font-size: 1.8rem;
		}

		.goubox .gouli .goutxt {
			font-size: 1.4rem;
			line-height: 2rem;
		}

		.goubox .gouli .goudi .gouleft {
			font-size: 1.4rem;
		}

		.goubox .gouli .goudi .gouright {
			font-size: 1.4rem;
		}

		.yanbg {
			height: 68rem;
			background-size: 100% 80rem;
		}

		.yanbg .yanul {
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.yanbg .yanul .yanli {
			width: 49%;
			margin-bottom: 1rem;
			height: 26rem;
			margin-right: 0 !important;
		}

		.yanbg .yanul .yanli .yanpic {
			width: 100%;
			height: 20rem;
		}

		.yanbg .yanul .yanli .yancen .yantitle {
			font-size: 1.7rem;
			padding-bottom: 1rem;
		}

		.yanbg .yanul .yanli .yancen .yanhua .huapic {
			width: 1.4rem;
			height: 1.4rem;
		}

		.yanbg .yanul .yanli .yancen .yanhua .yantxt {
			font-size: 1.4rem;
		}

		.yanbg .chamore {
			font-size: 1.5rem;
		}

		.zhibox .zhicen {
			flex-wrap: wrap;
			padding: 0 2rem;
		}

		.zhibox .zhicen .zhili {
			width: 100%;
			margin-bottom: 2rem;
		}

		.zhibox .zhicen .zhili .zhitop {
			width: 15rem;
			height: 4rem;
			line-height: 4rem;
			font-size: 1.6rem;
		}

		.zhibox .zhicen .zhili .zhired {
			width: 15rem;
			height: 4rem;
			line-height: 4rem;
			font-size: 1.6rem;
		}

		.zhibox .zhicen .zhili .chanli .cleft {
			width: 1.5rem;
		}

		.zhibox .zhicen .zhili .chanli .cleft .cpic {
			width: 1.5rem;
			height: 1.5rem;
		}

		.zhibox .zhicen .zhili .chanli .cleft .cline {
			height: 4rem;
		}

		.zhibox .zhicen .zhili .chanli .chancen .chantitle {
			font-size: 1.8rem;
			padding-bottom: 1.5rem;
		}

		.zhibox .zhicen .zhili .chanli .chancen .ctime .timepic {
			width: 1.4rem;
			height: 1.4rem;
		}

		.zhibox .zhicen .zhili .chanli .chancen .ctime .ctxt {
			font-size: 1.4rem;
		}

		.zhibox .zhicen .zhili .chanli .chanyou .cypic {
			width: 1.6rem;
			height: 1.4rem;
		}

		.zhibox .zhicen .zhili .chanli .chanyou .cytxt {
			font-size: 1.4rem;
		}

		.chuangbox .huiul {
			flex-wrap: wrap;
		}

		.chuangbox {
			height: 86rem;
			background-size: 100% 105rem;
		}

		.chuangbox .huiul .huili {
			width: 50%;
		}

		.chuangbox .huiul .huili .huipic {
			width: 4.6rem;
			height: 3.9rem;
		}

		.chuangbox .huiul .huili .huititle {
			font-size: 1.6rem;
		}

		.chuangbox .huiul .huili .huiabg {
			height: 25rem;
			background-size: 100% 25rem;
		}

		.chuangbox .huiul .huili .huibbg {
			height: 25rem;
			background-size: 100% 25rem;
		}

		.chuangbox .huiul .huili .huicbg {
			height: 25rem;
			background-size: 100% 25rem;
		}

		.chuangbox .huiul .huili .huidbg {
			height: 25rem;
			background-size: 100% 25rem;
		}

		.chuangbox .huiul .huili .huiebg {
			height: 25rem;
			background-size: 100% 25rem;
		}

		.chuangbox .huiul .huili {
			height: 25rem;
		}

		.chuangbox .huiul .huili:hover {
			background-color: #0164d3;
			height: 25rem;
		}

		.chuangbox .huiul .huili .huitxt {
			font-size: 1.4rem;
			line-height: 1.7rem;
		}

		.yuanbox .lunbox .lunli .lunleft {
			width: 50%;
		}

		.yuanbox .lunbox .lunli .lunpic {
			width: 48%;
		}

		.yuanbox .lunbox .lunli .lunleft .luntitle {
			font-size: 2rem;
		}

		.yuanbox .lunbox .lunli .lunleft .luntxt {
			font-size: 1.4rem;
			line-height: 1.8rem;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 6;
			overflow: hidden;
			padding-bottom: 0;
			margin-bottom: 3rem;
		}
	}

	/deep/.el-carousel__arrow {
		background-color: transparent !important;
		right: 1px;
	}

	/deep/.el-carousel__arrow i {
		font-size: 40px;
		color: #aaaaaa;
	}

	/deep/.el-carousel__arrow--left {
		left: 0;
	}

	/deep/.el-carousel__arrow i:hover {
		color: #0289f4 !important;
	}
</style>